<template lang="pug">
  .popup
    .popup__delete(@click="$emit('closePopup')")
    .popup__wrapper
      .popup__close(@click="$emit('closePopup')")
        span
        span
      h2 Your request is successfully submitted
</template>

<script>
export default {
  name: 'PopupBlock'
}
</script>
